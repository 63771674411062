import React from "react"
import ArrowPrev from "../images/logos/arrowPrev.svg"
function PrevArrow(props) {
  const { className, style, onClick } = props

  return (
    <img
      style={{
        ...style,
        display: "block",
        width: "1.5%",
        cursor: "pointer",
      }}
      onClick={onClick}
      src={ArrowPrev}
    />
  )
}

export default PrevArrow
