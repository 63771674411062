import React from "react"
import { Link } from "gatsby"
import styles from "../styles/homeBottom.module.css"
import Img from "gatsby-image"
import Linea from "../images/linea.svg"
import { useScrollData } from "scroll-data-hook"

const HomeBottom = ({ data }) => {
  const { position } = useScrollData()

  let Flag = data.flag.childImageSharp.fluid
  let Barrel = data.barrel.childImageSharp.fluid
  let Qletter = data.quilmesLetter.childImageSharp.fluid
  let BeerGlass = data.beerGlass.childImageSharp.fluid

  return (
    <>
      <div className={styles.background}>
        <div className={`${styles.container}`}>
          <div id={position.y > 1600 ? `${styles.glass}` : null}>
            <Img fluid={BeerGlass} />
          </div>

          <div className={styles.flagContainer}>
            <div id={position.y > 1600 ? `${styles.barrel}` : null}>
              <Img fluid={Barrel} />
            </div>
            <div id={position.y > 1600 ? `${styles.flagAnimation}` : null}>
              <Img fluid={Flag} />
            </div>

            <div className={`${styles.textContainer}`}>
              <h1
                className={
                  position.y > 1550 ? `${styles.slideAnimation}` : null
                }
              >
                HECHA
                <br /> CON CARIÑO
              </h1>
              <img src={Linea} />
              <h5
                className={
                  position.y > 1800 ? `${styles.slideAnimationText}` : null
                }
              >
                Conocé todo sobre nuestra historia, ingredientes y métodos de
                elaboración.
              </h5>

              <Link
                to="/history"
                onClick={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Content",
                    event_action: "Hecha con cariño",
                    event_label: `Ver Más`,
                    interaction: true,
                    component_name: "boton_ver_mas",
                    element_text: "Ver Más",
                  })
                }}
              >
                <button className={styles.button}>VER MÁS</button>
              </Link>
            </div>
          </div>
          <div id={position.y > 1600 ? `${styles.letter}` : null}>
            <Img fluid={Qletter} />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeBottom
