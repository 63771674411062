import React from "react"
import HomeArrow from "../../images/logos/homeArrow.svg"
import styles from "../../styles/home.module.css"
function PrevArrow(props) {
  const { className, style, onClick } = props

  return (
    <img className={styles.prevHomeArrow} onClick={onClick} src={HomeArrow} />
  )
}

export default PrevArrow
