import React from "react"
import { Link } from "gatsby"
import styles from "../styles/homeOverlay.module.css"
import downArrow from "../images/logos/downArrow.svg"
import HomeArrow from "../images/logos/homeArrow.svg"
import { useMediaQuery } from "react-responsive"
import BackgroundImage from "gatsby-background-image"
import { AiOutlineClose } from "react-icons/ai"
import * as Scroll from "react-scroll"
import { slickPause } from "react-slick"

let scroll = Scroll.animateScroll

const Slides = ({ isActive, setIsActive, slide, homeEdges, slider }) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  function handleClick() {
    scroll.scrollTo(705)
  }
  /*
  const prueba = `/En estos 130 años pasamos de todo:/ el crac del '29 y el ¡pum! de "nos cortaron las piernas", el rodrigazo, y el "dosmilunazo", la híper y las gripes. *Pero también hubo buenas*. Algunas hasta las vimos dos veces, en el '78 y en el '86, por ejemplo. Así que podemos decirte, con mucho lúpulo y sin escrúpulos, que esto también va a pasar.

  Pero eso no es todo. Queremos hacerte una *promesa* de cara al futuro.

  Primero que seguiremos trabajando para *seguir siendo la gran cerveza argentina*. Y segundo, que *siempre vamos a estar*. Porque siempre estuvimos. *Hace 130 años que somos de acá* y nos quedamos. Como cebada, el lúpulo y los choris.

  Son unas *gracias a precio amigo* que llevan un mensaje cálido, pero ¡ojo! Se toman frías. *Así que marchen un millón de gracias a todos los que nos eligen*.` */

  const slideImage = mobile
    ? homeEdges[slide.order].node.slide_image_mobile ? homeEdges[slide.order].node.slide_image_mobile.childImageSharp.fluid : null
    : homeEdges[slide.order].node.slide_image ? homeEdges[slide.order].node.slide_image.childImageSharp.fluid : null

  return (
    <div
      onClick={() => {
            setIsActive(!isActive)
      }}
      className={isActive ? styles.container : styles.containerActive}
    >
      <BackgroundImage className={styles.blueOverlay} fluid={slideImage}>
        <div className={styles.blueOverlayContent}>
          <AiOutlineClose
            onClick={() => {
              setIsActive(!isActive)
              slider.current.slickPlay()

            }}
            className={styles.closeButton}
          ></AiOutlineClose>
          <p>
            {slide.slideText.indexOf("*") > -1
              ? slide.slideText.split("*").map((n, i) =>
                  i % 2 === 0 ? (
                    <>{n}</>
                  ) : (
                    <strong>
                      <span>{n}</span>
                    </strong>
                  )
                )
              : slide.slideText}
          </p>
          {slide.slideButton ? (
            <a
              href={`${slide.slideHref}`}
              target="_blank"
              className={styles.overlayButton}
            >
              {slide.slideButtonText.toUpperCase()}
            </a>
          ) : null}
          <img alt="" onClick={handleClick} id={styles.arrow} src={downArrow} />{" "}
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Slides
