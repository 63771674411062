import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styles from "../styles/home.module.scss"
import downArrow from "../images/logos/arrow.svg"
import HomeSlide from "./homeSlides"
import Slider from "react-slick"
import PrevArrow from "./homeArrows/prevArrow"
import NextArrow from "./homeArrows/nextArrow"
import { useMediaQuery } from "react-responsive"
import * as Scroll from "react-scroll"
let LinkTo = Scroll.Link

const settings = {
  dots: true,
  dotsClass: `slick-dots ${styles.dotsHome}`,
  infinite: true,
  speed: 3000,
  autoplay: true,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  swipeToSLide: true,
  arrows: true,
  swipe: true,
  touchMove: true,
  className: `${styles.mobileSlider}`,
  prevArrow: <NextArrow></NextArrow>,
  nextArrow: <PrevArrow></PrevArrow>,
}

//LOGICA SIMILAR PARA AGREGAR IMAGEN MOBILE DESDE LOCALIMAGE

function HomeComponent({ data }) {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [isActive, setIsActive] = useState(true)
  const slider = useRef(null)

  function handleClick() {
    setIsActive(!isActive)
  }

  let homeData = data ? data.allHome.nodes.sort(function (a, b) {
    return a.order - b.order
  }) : []

  let homeEdges = data ? data.allHome.edges.sort(function (a, b) {
    return a.node.order - b.node.order
  }) : []

  let backImg = null
  return (
    homeData && homeData.length > 0 && <>
      <Slider
        ref={slider}
        {...settings}
        style={{ height: "100vh", display: "inline" }}
      >
        {homeData &&
          homeData.map((slide, idx) => {
            console.log("slide", slide)
            if(homeEdges[slide.order]){
              backImg = !mobile
                ? homeEdges[slide.order].node.image ? homeEdges[slide.order].node.image.childImageSharp.fluid : null
                : homeEdges[slide.order].node.image_mobile ? homeEdges[slide.order].node.image_mobile.childImageSharp.fluid : null
            }
              return (
              <BackgroundImage
                key={idx + 1000}
                className={styles.homeImg}
                fluid={backImg}
              >
                <div key={idx + 3000} className={styles.blackOverlay}>
                  {slide.slide ? (
                    <HomeSlide
                      settings={settings}
                      key={idx}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      slide={slide}
                      homeEdges={homeEdges}
                      slider={slider}
                    ></HomeSlide>
                  ) : null}

                  <div className={`${styles.container} ${styles.slideColText}`}>
                    {/* <div className={styles.textContainer}>
                      <h1 className={styles.home130text}>
                        {slide.homeTitle.toUpperCase()}
                      </h1>
                    </div> */}

                    <div className={styles.homeText}>
                      {/* <p>{slide.homeText}</p> */}

                      {slide.homeButton ? (
                        slide.homeHref ? (
                          <Link
                            style={{ textDecoration: "none" }}
                            className={styles.homeButton}
                            to={`${slide.homeHref}`}
                            // href={`/${slide.homeHref}`}
                            // target="_blank"
                          >
                            {slide.homeButtonText.toUpperCase()}
                          </Link>
                        ) : (
                          <button
                            style={{ textDecoration: "none" }}
                            className={styles.homeButton}
                            onClick={() => {
                              handleClick()
                              slider.current.slickPause()
                              // realiza la pausa tomando el useRef del Slider. Ver en homeSlides que está el slickPlay para que una vez cerrada la ventana vuelva el autoplay.
                            }}
                          >
                            {slide.homeButtonText.toUpperCase()}
                          </button>
                        )
                      ) : null}
                    </div>
                  </div>

                  <div className={isActive ? styles.arrowMain : styles.hidden}>
                    <LinkTo to="varieties" smooth={true}>
                      <div id={styles.circle}>
                        <div id={styles.circleFixed}>
                          <img alt="flecha para ir abajo" src={downArrow} />{" "}
                        </div>
                      </div>
                    </LinkTo>
                  </div>
                </div>
              </BackgroundImage>
            )
          })}
      </Slider>
    </>
  )
}

export default HomeComponent
