import React from "react"
import styles from "../styles/home-banner.module.scss"
import tadaLogo from "../images/logo-tada.png"
import { Link } from "gatsby"
import homeBanner from "../images/banners/banner-desktop.jpg"

const BannerDesktop = ({
  onFirstButtonClick,
  onSecondButtonClick,
  onThirdButtonClick,
  onTadaLogoClick,
}) => {
  return (
    <section className={styles.bannerContainerDesktop}>
      <div className={styles.left}>
        <h2>
          CAMPEÓN <br /> DEL MUNDO
        </h2>
        <p className={styles.text}>ELEGÍ DÓNDE CONSEGUIR TU DESCUENTO:</p>
        <div className={styles.buttons}>
          <a
            onClick={onFirstButtonClick}
            href={"https://link.tada.com.ar/3qSL/ECONVENIENCIA"}
            target={"_blank"}
            className={styles.cta}
          >
            EN TU CASA, CON ENVÍO <br /> GRATIS
          </a>
          <a
            onClick={onSecondButtonClick}
            href={"https://beescoupons.com/promoquilmes"}
            target={"_blank"}
            className={styles.cta}
          >
            EN LOCALES <br /> ADHERIDOS
          </a>
          <Link
            to={"/localesadheridos"}
            className={styles.mapCtaContainer}
            onClick={onThirdButtonClick}
          >
            <p className={styles.mapCta}>
              <u>ENCONTRÁ EL LOCAL MÁS CERCANO ACÁ</u>
            </p>
          </Link>
        </div>
        <div className={styles.phraseContainer}>
          <div className={styles.phrase}>
            <span>VOS ELEGÍ DÓNDE Y CUÁNTO</span>
          </div>
          <div className={styles.phrase}>
            <span>
              <b>
                CON{" "}
                <a
                  onClick={onTadaLogoClick}
                  href={
                    "https://www.tada.com.ar/?af_xp=custom&source_caller=ui&pid=WEBSITE&is_retargeting=true&af_click_lookback=7d&shortlink=ECONVENIENCIA&deep_link_value=WEBSITE&af_reengagement_window=7d&c=ARG-BRA-QUILMES-CRO-ALL-WEBSITE-CONVERSION-28D-NACIONAL-CONVENIENCIA--"
                  }
                  target={"_blank"}
                >
                  <img src={tadaLogo} alt={"TaDá"} />
                </a>
                , NOS
              </b>{" "}
              <b>ENCARGAMOS DEL RESTO.</b>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.right}></div>
    </section>
  )
}

const BannerMobile = ({
  onFirstButtonClick,
  onSecondButtonClick,
  onThirdButtonClick,
  onTadaLogoClick,
}) => {
  return (
    <section className={styles.bannerContainerMobile}>
      <div className={styles.top}>
        <div className={styles.filter}></div>
      </div>
      <div className={styles.bottom}>
        <h1>CAMPEÓN DEL MUNDO</h1>
        <h2>ELEGÍ DÓNDE CONSEGUIR TU DESCUENTO</h2>
        <div className={styles.buttons}>
          <a
            onClick={onFirstButtonClick}
            href={"https://link.tada.com.ar/3qSL/ECONVENIENCIA"}
            target={"_blank"}
          >
            EN TU CASA, <br /> CON ENVÍO <br /> GRATIS
          </a>
          <a
            onClick={onSecondButtonClick}
            href={"https://beescoupons.com/promoquilmes"}
            target={"_blank"}
          >
            EN LOCALES <br /> ADHERIDOS
          </a>
        </div>
        <Link to={"/localesadheridos"} onClick={onThirdButtonClick}>
          <p className={styles.mapCta}>
            <u>ENCONTRÁ EL LOCAL MÁS CERCANO ACÁ</u>
          </p>
        </Link>
        <div className={styles.phraseContainer}>
          <div className={styles.phrase}>
            <span>VOS ELEGÍ DÓNDE Y CUÁNTO</span>
          </div>
          <div className={styles.phrase}>
            <span>
              CON{" "}
              <a
                onClick={onTadaLogoClick}
                href={
                  "https://www.tada.com.ar/?af_xp=custom&source_caller=ui&pid=WEBSITE&is_retargeting=true&af_click_lookback=7d&shortlink=ECONVENIENCIA&deep_link_value=WEBSITE&af_reengagement_window=7d&c=ARG-BRA-QUILMES-CRO-ALL-WEBSITE-CONVERSION-28D-NACIONAL-CONVENIENCIA--"
                }
                target={"_blank"}
              >
                <img src={tadaLogo} alt={"TaDá"} />
              </a>
              , NOS ENCARGAMOS DEL RESTO.
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

const HomeBanner = () => {
  const onFirstButtonClick = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: "Boton",
      event_label: "TaDá",
      interaction: "True",
      component_name: "boton_tada",
      element_text: "TaDá",
    })
  }

  const onSecondButtonClick = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: "Boton",
      event_label: "Coupons",
      interaction: "True",
      component_name: "boton_coupons",
      element_text: "Coupons",
    })
  }

  const onThirdButtonClick = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: "Promo Quilmes",
      event_label: "Encontrá el Local más cercano",
      interaction: "True",
      component_name: "boton_local_cercano",
      element_text: "Encontrá el Local más cercano",
    })
  }

  const onTadaLogoClick = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: "Boton",
      event_label: "Sombrero",
      interaction: "True",
      component_name: "boton_tada",
      element_text: "TaDá",
    })
  }

  const dueDate = new Date("2024-04-14")
  const currentDate = new Date()
  const showNewBanner = currentDate > dueDate

  return showNewBanner ? (
    <>
      <a href={"https://www.instagram.com/p/C8cvIVepYcD/"} target={"_blank"}>
        <div className={styles.newBannerDesktop} />
        <div className={styles.newBannerMobile} />
      </a>
      {/*<div className={styles.secondBannerDesktop}/>*/}
      {/*<div className={styles.secondBannerMobile}/>*/}
    </>
  ) : (
    <>
      <BannerDesktop
        onFirstButtonClick={onFirstButtonClick}
        onSecondButtonClick={onSecondButtonClick}
        onThirdButtonClick={onThirdButtonClick}
        onTadaLogoClick={onTadaLogoClick}
      />
      <BannerMobile
        onFirstButtonClick={onFirstButtonClick}
        onSecondButtonClick={onSecondButtonClick}
        onThirdButtonClick={onThirdButtonClick}
        onTadaLogoClick={onTadaLogoClick}
      />
    </>
  )
}

export default HomeBanner
