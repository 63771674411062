import React, { useState, useEffect } from "react"
import Varieties from "../components/varieties"
import Layout from "../components/layout"
import Places from "../components/places"
import HomeBottom from "../components/homeBottom"
import HomeDesktop from "../components/homeDesktop"
import SEO from "../components/seo"
import { navigate } from "gatsby"

import "../styles/global.css"
import HomeBanner from "../components/homeBanner"

const Home = ({ data, location }) => {
  const [isActive, setIsActive] = useState(true)
  const [localActive, setLocalActive] = useState(false)
  const [sessionActive, setSessionActive] = useState(false)

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (session || local) {
      window.dataLayer.push({
        brand: "Quilmes",
        Zone: "SAZ",
        country: "ARG",
        city: "CABA",
        campaign: `${
          window.location.search.includes("?cmp=")
            ? window.location.search.split("?cmp=")[1].split("&")[0]
            : ""
        }`,
        cms: "0",
        event: "pageView",
        language: "es",
        login: false,
        pageName: "Inicio",
        pageType: "Basic page",
        SiteType: "Brand",
        product: "NA",
        sku: "NA",
        userUid: "",
        url: "https://www.quilmes.com.ar",
        url_campaign: `${
          window.location.search.includes("utm_campaign=")
            ? window.location.search.split("utm_campaign=")[1]
            : ""
        }`,
        Step_number: "",
      })
    }
  }, [])

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local) navigate("/agegate" + location.search)
    if (local) setLocalActive(true)
    if (session) setSessionActive(true)
  }, [])

  return (
    (sessionActive || localActive) && (
      <>
        <Layout>
          <SEO title={"Quilmes"} description={""} />
          <HomeBanner/>
          <HomeDesktop
            data={data}
            setIsActive={setIsActive}
            isActive={isActive}
          ></HomeDesktop>
          <div id="varieties" style={{marginTop:"-1vh"}}>
            <Varieties data={data}></Varieties>
          </div>
          <div id="places">
            <Places data={data}></Places>
          </div>
          <HomeBottom data={data}></HomeBottom>
        </Layout>
      </>
    )
  )
}

export default Home

export const query = graphql`
  query {
    homeImage: file(relativePath: { eq: "homeImg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeMobile: file(relativePath: { eq: "homeBackMobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placesBackImage: file(relativePath: { eq: "picadamobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placesBackImageDesktop: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    quilmesLetter: file(relativePath: { eq: "8.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    barrel: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beerGlass: file(relativePath: { eq: "7.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flag: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allImages: allFile {
      nodes {
        relativePath
      }
    }
    clasicaBack: file(relativePath: { eq: "varbackg1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stoutBack: file(relativePath: { eq: "varbackg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lagerBack: file(relativePath: { eq: "varbackg3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bockBack: file(relativePath: { eq: "varbackg5.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ceroBack: file(relativePath: { eq: "varbackg4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    clasicaBack: file(relativePath: { eq: "varbackg1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    defaultBack: file(relativePath: { eq: "varietiesHomeImg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allHome {
      nodes {
        id
        homeBackImg
        homeBackImgMobile
        slideImg
        slideImgMobile
        homeTitle
        homeButton
        homeHref
        homeButtonText
        homeText
        slide
        slideText
        slideButton
        slideHref
        slideButtonText
        order
      }
    }
    allBeer {
      nodes {
        bitter
        canImg
        characteristics
        description
        grad
        homeBackImg
        homeBackImgMobile
        id
        logo
        name
        pairing
        pairingBack
        slug
        temperature
        order
        buyLink
      }
    }
    allHome {
      edges {
        node {
          id
          order
          image {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_mobile {
            childImageSharp {
              fluid(maxWidth: 375) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slide_image {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slide_image_mobile {
            childImageSharp {
              fluid(maxWidth: 375) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allBeer {
      edges {
        node {
          id
          order
          back_image {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          back_image_mobile {
            childImageSharp {
              fluid(maxWidth: 375) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
